import React from 'react';
import { Card, CardContent, Typography, IconButton, Box } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SaveIcon from '@mui/icons-material/Save';
import { useTranslation } from 'react-i18next';
import '../../css/StoryCard.css';

const cleanStoryText = (text) => {
    return text.replace(/[*]/g, '').trim();
};

const parseUserStory = (description) => {
    const parseSpanish = (desc) => {
        const roleMatch = desc.match(/^Como\s+([^,]+?),/i);
        if (!roleMatch) return null;

        const role = roleMatch[1].trim();
        const rest = desc.slice(roleMatch[0].length).trim();
        const lastParaIndex = rest.toLowerCase().lastIndexOf(' para ');

        if (lastParaIndex === -1) return null;

        const action = rest.slice(0, lastParaIndex).trim();
        const reason = rest.slice(lastParaIndex + 6).trim().replace(/\.$/, '');

        return { role, action, reason };
    };

    const parseEnglish = (desc) => {
        // Nueva expresión regular mejorada
        const englishPattern = /^as a\s*([^,]+?)\s*,?\s*i want\s+(.+?)\s+so that\s+(.+)/i;
        const match = desc.match(englishPattern);

        if (!match) return null;

        return {
            role: match[1].trim(),
            action: match[2].trim().replace(/\.$/, ''),
            reason: match[3].trim().replace(/\.$/, '')
        };
    };

    try {
        if (/^Como\s+/i.test(description)) {
            const parsed = parseSpanish(description);
            if (parsed) {
                return {
                    parts: [
                        { label: 'Como:', text: parsed.role },
                        { label: 'Quiero:', text: parsed.action },
                        { label: 'Para:', text: parsed.reason }
                    ],
                    valid: true
                };
            }
        } else {
            const parsed = parseEnglish(description);
            if (parsed) {
                return {
                    parts: [
                        { label: 'As a:', text: parsed.role },
                        { label: 'I want:', text: parsed.action },
                        { label: 'So that:', text: parsed.reason }
                    ],
                    valid: true
                };
            }
        }
    } catch (e) {
        console.error('Error parsing user story:', e);
    }

    return { parts: null, valid: false };
};

const StoryCard = ({ story, onSave }) => {
    const { t } = useTranslation();
    const parsed = parseUserStory(story.description);

    const handleCopyToClipboard = () => {
        const cleanText = cleanStoryText(story.description);
        navigator.clipboard.writeText(cleanText);
    };

    return (
        <Card className="customStoryCard storyCard">
            <CardContent>
                <Box className="storyCardBox">
                    <Box className="storyCardText">
                        <Typography
                            className="storyTitle"
                            style={{
                                fontWeight: 'bold',
                                marginBottom: '16px'
                            }}
                        >
                            {story.title}
                        </Typography>

                        {parsed.parts ? (
                            parsed.parts.map((part, index) => (
                                <Typography
                                    key={index}
                                    component="div"
                                    className="storyDescription"
                                    style={{
                                        whiteSpace: 'pre-line',
                                        marginBottom: index === parsed.parts.length - 1 ? 0 : '0.5rem',
                                        marginTop: index === 0 ? '8px' : 0
                                    }}
                                >
                                    <span style={{ fontWeight: 'bold' }}>{part.label}</span> {part.text}
                                </Typography>
                            ))
                        ) : (
                            <Typography className="storyDescription">
                                {story.description}
                            </Typography>
                        )}
                    </Box>

                    <Box display="flex" flexDirection="column" alignItems="flex-end" mt={2}>
                        <IconButton onClick={handleCopyToClipboard} color="primary" title={t('copyToClipboardButton')}>
                            <ContentCopyIcon />
                        </IconButton>
                        <IconButton onClick={() => onSave(story)} color="primary" title={t('saveStoryButton')}>
                            <SaveIcon />
                        </IconButton>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

export default StoryCard;