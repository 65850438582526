import React, { useState, useEffect, useMemo } from 'react';
import {
    Card,
    CardContent,
    CardActions,
    Button,
    Grid,
    Typography,
    IconButton,
    Box,
    CircularProgress
} from '@mui/material';
import { ExpandMore, ExpandLess, ContentCopy } from '@mui/icons-material';
import { useProduct } from '../../context/ProductContext';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const ProductTree = () => {
    const { selectedProduct, products } = useProduct();
    const { t } = useTranslation();
    const [hierarchy, setHierarchy] = useState([]);
    const [children, setChildren] = useState([]);
    const [expandedDescriptions, setExpandedDescriptions] = useState(new Set());
    const [loading, setLoading] = useState(false);

    const typeColors = {
        product: {
            background: 'rgba(33, 150, 243, 0.08)',
            border: 'rgba(33, 150, 243, 0.3)'
        },
        epic: {
            background: 'rgba(156, 39, 176, 0.08)',
            border: 'rgba(156, 39, 176, 0.3)'
        },
        story: {
            background: 'rgba(76, 175, 80, 0.08)',
            border: 'rgba(76, 175, 80, 0.3)'
        },
        criterion: {
            background: 'rgba(255, 193, 7, 0.08)',
            border: 'rgba(255, 193, 7, 0.3)'
        }
    };

    const product = useMemo(() =>
        products.find(p => p.product_id === selectedProduct),
        [selectedProduct, products]);

    const currentParent = useMemo(() =>
        hierarchy.length > 0 ? hierarchy[hierarchy.length - 1] : null,
        [hierarchy]);

    useEffect(() => {
        if (product && (!hierarchy[0] || hierarchy[0].data.product_id !== product.product_id)) {
            setHierarchy([{ type: 'product', data: product }]);
        }
    }, [product]);

    useEffect(() => {
        const fetchChildren = async () => {
            if (!currentParent) {
                setChildren([]);
                return;
            }

            let endpoint = '';
            const { type, data } = currentParent;

            switch (type) {
                case 'product':
                    endpoint = `/api/epics/product/${data.product_id}`;
                    break;
                case 'epic':
                    endpoint = `/api/stories/epic/${data.epic_id}`;
                    break;
                case 'story':
                    endpoint = `/api/acceptance-criteria/story/${data.user_story_id}`;
                    break;
                default:
                    setChildren([]);
                    return;
            }

            setLoading(true);
            try {
                const response = await axios.get(endpoint);
                let newData = [];

                if (type === 'story') {
                    newData = Array.isArray(response.data)
                        ? response.data.map(c => ({
                            id: c.acceptance_criteria_id,
                            description: c.description,
                            user_story_id: data.user_story_id
                        }))
                        : [];
                } else {
                    newData = Array.isArray(response.data) ? response.data : [];
                }

                setChildren(prev => JSON.stringify(prev) === JSON.stringify(newData) ? prev : newData);
            } catch (error) {
                console.error('Error fetching children:', error);
                setChildren([]);
            } finally {
                setLoading(false);
            }
        };

        fetchChildren();
    }, [currentParent]);

    const getChildType = () => {
        if (!currentParent) return '';
        return {
            product: 'epic',
            epic: 'story',
            story: 'criterion'
        }[currentParent.type];
    };

    const handleToggleChildren = (child) => {
        const childType = getChildType();
        const isCurrentChild = hierarchy.some(item =>
            item.type === childType && item.data.id === child.id
        );

        setHierarchy(prev => isCurrentChild
            ? prev.filter(item => !(item.type === childType && item.data.id === child.id))
            : [...prev, { type: childType, data: child }]
        );
    };

    const getButtonLabel = (child) => {
        const childType = getChildType();
        const labels = {
            product: t('productTreeEpicsLabel'),
            epic: t('productTreeStoriesLabel'),
            story: t('productTreeCriteriaLabel')
        };
        const isOpen = hierarchy.some(item => item.type === childType && item.data.id === child.id);
        return `${isOpen ? t('productTreeCloseButton') : t('productTreeOpenButton')} ${labels[childType]}`;
    };

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
        alert('Copiado al portapeles');
    };

    const toggleDescription = (id) => {
        setExpandedDescriptions(prev => {
            const newSet = new Set(prev);
            newSet.has(id) ? newSet.delete(id) : newSet.add(id);

            setTimeout(() => {
                const element = document.getElementById(`desc-${id}`);
                element?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }, 50);

            return newSet;
        });
    };

    return (
        <Box sx={{ p: 2, maxWidth: 1200, margin: '0 auto' }}>
            {hierarchy.map((item, index) => {
                const colors = typeColors[item.type];
                return (
                    <Box key={`${item.type}-${item.data.id}`} sx={{ mb: 2 }}>
                        <Card
                            variant="outlined"
                            sx={{
                                cursor: 'pointer',
                                transition: 'all 0.2s',
                                borderLeft: `4px solid ${colors.border}`,
                                backgroundColor: colors.background,
                                '&:hover': {
                                    transform: 'translateY(-2px)',
                                    boxShadow: 1
                                }
                            }}
                            onClick={() => setHierarchy(hierarchy.slice(0, index + 1))}
                        >
                            <CardContent>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="h6" color="text.primary">
                                        {item.data.name || t('productTreeNoTitle')}
                                    </Typography>
                                    <IconButton
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            toggleDescription(item.data.id);
                                        }}
                                        size="small"
                                    >
                                        {expandedDescriptions.has(item.data.id) ? (
                                            <ExpandLess fontSize="small" />
                                        ) : (
                                            <ExpandMore fontSize="small" />
                                        )}
                                    </IconButton>
                                </Box>

                                {expandedDescriptions.has(item.data.id) && (
                                    <Typography
                                        id={`desc-${item.data.id}`}
                                        variant="body2"
                                        color="text.secondary"
                                        sx={{ mt: 1, whiteSpace: 'pre-wrap' }}
                                    >
                                        {item.data.description || t('productTreeNoDescription')}
                                    </Typography>
                                )}
                            </CardContent>
                        </Card>
                    </Box>
                );
            })}

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <CircularProgress size={40} />
                </Box>
            ) : (
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    {children.map(child => {
                        const childType = getChildType();
                        const colors = typeColors[childType];
                        const isSingleStory = childType === 'story' && children.length === 1;
                        const isCriterion = childType === 'criterion';

                        return (
                            <Grid
                                item
                                xs={12}
                                sm={isSingleStory || isCriterion ? 12 : 6}
                                md={isSingleStory || isCriterion ? 12 : 4}
                                key={child.id}
                            >
                                <Card
                                    variant="outlined"
                                    sx={{
                                        width: '100%',
                                        borderLeft: `4px solid ${colors.border}`,
                                        backgroundColor: colors.background,
                                        transition: 'all 0.2s',
                                        '&:hover': {
                                            transform: 'translateY(-2px)',
                                            boxShadow: 1
                                        }
                                    }}
                                >
                                    <CardContent>
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                            <Typography
                                                variant="subtitle1"
                                                color="text.primary"
                                                sx={{ fontWeight: isCriterion ? 500 : 600 }}
                                            >
                                                {child.name || child.description?.substring(0, 40) + '...' || t('productTreeNoTitle')}
                                            </Typography>
                                            <IconButton
                                                size="small"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    toggleDescription(child.id);
                                                }}
                                            >
                                                {expandedDescriptions.has(child.id) ? (
                                                    <ExpandLess fontSize="small" />
                                                ) : (
                                                    <ExpandMore fontSize="small" />
                                                )}
                                            </IconButton>
                                        </Box>

                                        {expandedDescriptions.has(child.id) && (
                                            <Typography
                                                id={`desc-${child.id}`}
                                                variant="body2"
                                                color="text.secondary"
                                                sx={{ mt: 1, whiteSpace: 'pre-wrap' }}
                                            >
                                                {child.description || t('productTreeNoDescription')}
                                            </Typography>
                                        )}
                                    </CardContent>

                                    <CardActions sx={{ justifyContent: 'space-between' }}>
                                        <Box>
                                            {childType !== 'criterion' && (
                                                <Button
                                                    size="small"
                                                    onClick={() => handleToggleChildren(child)}
                                                    sx={{ textTransform: 'none' }}
                                                >
                                                    {getButtonLabel(child)}
                                                </Button>
                                            )}
                                        </Box>
                                        <IconButton
                                            size="small"
                                            onClick={() => handleCopy(child.description)}
                                            sx={{ color: 'text.secondary' }}
                                        >
                                            <ContentCopy fontSize="small" />
                                        </IconButton>
                                    </CardActions>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            )}
        </Box>
    );
};

export default ProductTree;
