import React from 'react';
import { Container, Typography, Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import '../css/style.css';

function AboutUs() {
    const { t } = useTranslation();

    return (
        <Container maxWidth="lg">
            <Box sx={{ my: 4 }}>
                <Typography variant="h4" gutterBottom>
                    {t('aboutUsTitle')}
                </Typography>
                <img src="img/santiago-chile-larga2.jpg" alt={t('introImageAlt')} className="aboutUs-image" />
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('aboutUsWhoWeAre')}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {t('aboutUsWhoWeAreText')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('aboutUsOurMission')}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {t('aboutUsOurMissionText')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('aboutUsWhyWeDoIt')}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {t('aboutUsWhyWeDoItText')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('aboutUsOurVision')}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {t('aboutUsOurVisionText')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('aboutUsOurApproach')}
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            {t('aboutUsUserCentricDesign')}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {t('aboutUsUserCentricDesignText')}
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            {t('aboutUsAIPoweredEfficiency')}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {t('aboutUsAIPoweredEfficiencyText')}
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            {t('aboutUsContinuousImprovement')}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {t('aboutUsContinuousImprovementText')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('aboutUsJoinUs')}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {t('aboutUsJoinUsText')}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

export default AboutUs;
