import React from 'react';
import { Container, Typography, Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import '../css/style.css';

function UseCases() {
    const { t } = useTranslation();

    return (
        <Container maxWidth="lg">
            <Box sx={{ my: 4 }}>
                <Typography variant="h4" gutterBottom style={{ marginBottom: '40px' }}>
                    {t('useCasesTitle')}
                </Typography>
                <img src="img/teamwork.jpg" alt={t('introImageAlt')} className="useCases-image" />
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('useCaseCostReductionTitle')}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {t('useCaseCostReductionText')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('useCaseEfficiencyTitle')}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {t('useCaseEfficiencyText')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('useCaseOnboardingTitle')}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {t('useCaseOnboardingText')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('useCaseCreativityTitle')}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {t('useCaseCreativityText')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('useCaseStandardizationTitle')}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {t('useCaseStandardizationText')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('useCaseBacklogTitle')}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {t('useCaseBacklogText')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('useCaseRemoteSupportTitle')}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {t('useCaseRemoteSupportText')}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

export default UseCases;