import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    en: {
        translation: {
            header: "AI PO Helper",
            contextLabel: "Provide a product description: what it is, its purpose, target audience, features, technologies, and any relevant information",
            functionalityLabel: "Name the specific functionality to be developed",
            generateButton: "Generate user story",
            generatingButton: "Generating...",
            decomposeButton: "Break it down into more stories",
            copyButton: "Copy",
            userStory: "User story:",
            additionalInfoPlaceholder: "Enter additional information (optional)",
            errorGeneratingStory: "Error generating user story:",
            generateUserStories: "Break down user story",
            generatingUserStories: "Breaking down user stories...",
            decomposedStories: "Broken down stories:",
            navBarTitle: "",
            additionalInfoHeader: "Additional story information",
            introText: "AI PO Helper is a platform that, through Artificial Intelligence, helps you optimize your tasks as a Product Owner, saving you time and supporting you in developing high-quality products.",
            describeProduct: "Describe your product",

            // promptTemplate1: "Given the context of development and product construction, including user details and purpose: '{{context}}'.\n\nGenerate a user story based on the specific functionality: '{{functionality}}', in the format: 'As a <role>, I want <action to be taken> so that <goal>. The story should start with 'Title: ' followed by a brief title summarizing the main action in a few words, and then the story text. Keep the story text concise, short and direct (maximum 4 lines long)",            // promptTemplate: "Given the context of development and product construction, including user details and purpose: '{{context}}'.\n\nGenerate a user story based on the specific functionality: '{{functionality}}', in the format: 'As a <role>, I want <action to be taken> so that <goal>. The story should start with 'Title: ' followed by a brief title summarizing the main action in a few words, and then the story text.'",
            promptTemplate: "**CRITICAL INSTRUCTIONS**||CONTEXT:'{{context}}'||EPIC:'{{epic}}'||**MANDATORY FORMAT:**||Line1: 'Title: [3-5 epic-related words]'||Line2: 'As a [context-specific role], I want to [epic-verb action] so that [context-aligned goal]'||**STRICT RULES:**||✅ ROLE: Must contain keywords from: {{context}}||✅ ACTION: Must start with verb from: {{epic}}||✅ GOAL: Must reference: {{context}} benefits||❌ NEVER USE: 'able to', 'wish', 'need', 'would like'||**VALID EXAMPLE:**||Context: E-commerce platform||Epic: Payment error handling||→ 'Title: Failed transaction analysis\\nAs a fraud analyst, I want to review declined payments so that I can detect suspicious patterns'",

            promptDecomposeTemplate: "Based on the original story: '{{originalStory}}', and considering the additional context provided: '{{additionalInfo}}', please generate more specific user stories that are directly related to the original functionality and context. Each story should start with 'Title: ' followed by a brief title summarizing the main action in a few words, and then the story text. Each story should be separated by line breaks, with no numbers or special characters before or after the story text.",
            homeTitle: "Optimize your productivity in the world of agility with AI",
            titleGenerateStory: "User Stories",
            homeTitleGenerateStory: "Create user stories easily",
            homeDescriptionGenerateStory: "Boost your team's understanding and achieve better results!",
            descriptionGenerateStory: "Focus on what really matters and generate complete, high-quality user stories",
            cardAcceptanceCriteriaTitle: "Acceptance criteria",
            generateAcceptanceCriButton: "Generate acceptance criteria",
            copyToClipboardButton: "Copy to clipboard",
            landingStartButton: "Get Started for Free!",
            advantagesLanding: "Highlights",
            titleBlock1Landing: "Automate your stories",
            "descBlock1Landing": "Automatically generates user stories.|Captures requirements with ease and accuracy.|Ideal for maintaining consistency in project documentation.",
            titleBlock2Landing: "Save time",
            "descBlock2Landing": "Drastically reduces planning time.|Frees up resources for development and design.|Automates repetitive processes and improves efficiency.",
            titleBlock3Landing: "Create better products",
            "descBlock3Landing": "Enhances the quality of the final product.|Ensures that user and stakeholder expectations are clear and achievable.|Minimizes the risks of misunderstandings and ensures a common understanding.",
            "generateEpicsButton": "Generate",
            "generateEpicsDesc": "Divide your product development into epics to plan its construction in the best way possible",
            "titleGenerateAcceptanceCriteria": "Acceptance Criteria",
            "descriptionAcceptanceCriteria": "Generate the acceptance criteria for your user stories.",
            "nameUserStoryLabel": "Enter the user story from which you want the acceptance criteria.",
            "generateAcceptanceCriteriaButton": "Generate Acceptance Criteria",
            "promptAcceptanceCriteria": 'Generate an enumerated list of detailed acceptance criteria, each separated by a line break. The focus of the criteria should be on what the following user story provided says which is Story: {{storyDescription}} and taking account the context of the product which is Context: {{context}}, make sure to cover all technical and functional aspects. Respond only with the list.',
            "workspaceMainTitle": "Select an option from the menu",
            "menuButtonEpics": "Epics",
            "menuButtonUserStories": "User Stories",
            "menuButtonCriteria": "Acceptance Criteria",
            // WelcomeCard translations
            "welcomeCardTitle": "AI PO Helper",
            "welcomeCardDescription": "Select an option from the menu to get started, or use the buttons below to create new content.",
            "welcomeCardDescriptionPrivate": "Welcome! Select a product from the menu to get started or create one in the dedicated section.",
            "welcomeCardDescriptionNoProductPrivate": "Welcome! Create a new product to get started.",
            "welcomeEpicButton": "Epic",
            "welcomeStoryButton": "User Story",
            "welcomeCriteriaButton": "Acceptance Criteria",
            "welcomeStartButton": "Start",
            // ProductForm
            createProductDesc: "A product is an item or system developed to meet user needs, delivering value through iterative and incremental development.",
            createProductTitle: "Product",
            productNameLabel: "Product Name",
            productDescriptionLabel: "Product Description",
            saveProduct: "Save",
            cancelProduct: "Cancel",
            editProductTitle: "Edit Product",
            addProductTitle: "Add Product",
            searchProductTitle: "Search and Edit Product",
            filterStatusLabel: "Filter by Status",
            searchByNameLabel: "Search by Name",
            productSavedSuccess: "Product saved successfully",
            productSaveError: "Error saving product",
            deleteProduct: "Delete",
            productDeletedSuccess: "Product deleted successfully",
            productDeletedError: "Error deleting product",
            productAddDescPlaceHolder: "Include at least the product's purpose, main users, features, and technical aspects. The more detailed, the better the quality",
            // Menú
            menuButtonProduct: "Products",
            menuButtonAddProduct: "Add Product",
            menuButtonEditProduct: "Edit Product",
            // Epics
            generateEpicsTitle: "Generate Epics",
            addEpicTitle: "Add Epic",
            editEpicTitle: "Edit Epic",
            all: "All",
            active: "Active",
            inactive: "Inactive",
            in_development: "In Development",
            "epicsPrompt": `Generate all the necessary epics to successfully develop the product based on the provided product description: "{{context}}". For each epic, provide a non-generic title and a description of no more than 3 lines. Only print the generated epic title and its description, do not use list numbers or colons (:) after the epic title. Strictly adhere to the format, with a double line break between each epic. Format: Title \n Description \n\n Title \n Description \n\n ...`,
            epicTitleLabel: "Epic Title",
            epicDescriptionLabel: "Epic Description",
            saveEpic: "Save",
            cancelEpic: "Cancel",
            epicSavedSuccess: "Epic saved successfully!",
            epicSaveError: "Error saving epic.",
            epicUpdatedSuccess: "Epic updated successfully!",
            epicUpdateError: "Error updating epic.",
            epicDeletedSuccess: "Epic deleted successfully!",
            epicDeleteError: "Error deleting epic.",
            deleteEpic: "Delete",
            // User Stories
            "generateStoryTitle": "Generate Story",
            "addStoryTitle": "Add Story",
            "editStoryTitle": "Edit Story",
            "selectStoryLabel": "Select Story",
            "storyTitleLabel": "Story Title",
            "storyDescriptionLabel": "Story Description",
            "generateStoriesButton": "Generate User Story",
            "saveStory": "Save",
            "cancelStory": "Cancel",
            "deleteStory": "Delete",
            "storySavedSuccess": "Story saved successfully",
            "storySaveError": "Error saving story",
            "storyUpdatedSuccess": "Story updated successfully",
            "storyUpdateError": "Error updating story",
            "storyDeletedSuccess": "Story deleted successfully",
            "storyDeleteError": "Error deleting story",
            "saveStoryButton": "Save story",
            "storiesPrompt": "Generate a series of user stories based on the provided epic context: '{{context}}'. For each story, provide a title and a description. Do not enumerate the stories. Each story and its description should be separated from the next by two line breaks.",
            // Acceptance Criteria
            "generateCriteriaTitle": "Generate Criteria",
            "addCriteriaTitle": "Add Criteria",
            "editCriteriaTitle": "Edit Criteria",
            "selectProductLabel": "Select Product",
            "selectEpicLabel": "Select Epic",
            "selectStoryLabel": "Select User Story",
            "selectCriteriaLabel": "Select Acceptance Criteria",
            "criteriaDescriptionLabel": "Acceptance Criteria Description",
            "generateCriteriaButton": "Generate Criteria",
            "saveCriteria": "Save",
            "cancelCriteria": "Cancel",
            "saveCriteriaButton": "Save",
            "criteriaSavedSuccess": "Acceptance Criteria saved successfully.",
            "criteriaSaveError": "Error saving Acceptance Criteria.",
            "criteriaUpdatedSuccess": "Acceptance Criteria updated successfully.",
            "criteriaUpdateError": "Error updating Acceptance Criteria.",
            "criteriaDeletedSuccess": "Acceptance Criteria deleted successfully.",
            "criteriaDeleteError": "Error deleting Acceptance Criteria.",
            "errorGeneratingCriteria": "Error generating Acceptance Criteria:",
            deleteCriteria: "Delete",
            "selectProductSnackbar": "Please select a product before proceeding.",
            // Session
            "signOut": "Sign Out",
            "signUpTitle": "Sign Up",
            // Generate All Stories
            "promptGenerateEpicStories": "Given the context of development and product construction, including user details and purpose: '{{context}}'. Considering the epic description: '{{epicDescription}}'.\n\nGenerate a list of user stories that encompass the epic. Each story should follow the format: 'As a <role>, I want <action to be taken> so that <goal>. Each story should start with 'Title: ' followed by a brief title summarizing the main action in a few words, and then the story text.'",
            "generateEpicStoriesTitle": "Generate All",
            "promptDecomposeEpicStories": "Based on the original story: '{{originalStory}}', and considering the additional context provided: '{{additionalInfo}}', please generate more specific user stories that are directly related to the original functionality and context. Each story should start with 'Title: ' followed by a brief title summarizing the main action in a few words, and then the story text. Each story should be separated by line breaks, with no numbers or special characters before or after the story text.",
            // Footer
            linksTitle: "Links",
            aboutUsLinkTitle: "About Us",
            blogLinkTitle: "Blog",
            tutorialsLinkTitle: "Tutorials",
            faqsLinkTitle: "FAQs",
            contactLinkTitle: "Contact",
            resourcesTitle: "Resources",
            useCasesLinkTitle: "Use Cases",
            testimonialsLinkTitle: "Testimonials",
            legalTitle: "Legal",
            termsLinkTitle: "Terms and Conditions",
            privacyLinkTitle: "Privacy Policy",
            // About Us
            aboutUsTitle: "About Us",
            aboutUsWhoWeAre: "Who We Are",
            aboutUsWhoWeAreText: "At AI PO Helper, we are passionate about building digital products and enhancing productivity through agile methodology. Our mission is to create a tool that empowers every team to focus on what truly matters: delivering value to customers. Our journey began with a deep understanding of the challenges faced by product owners and agile teams, where the scarcity of time during planning, writing user stories, and effectively communicating them to development teams often leads to inefficiencies and misunderstandings.",
            aboutUsOurMission: "Our Mission",
            aboutUsOurMissionText: "We have experienced firsthand the crunch of tight schedules and the struggle to maintain clarity and understanding in our goals. That's why we believe in the transformative power of Artificial Intelligence (AI) to assist in these crucial tasks. AI PO Helper is designed to streamline the process of creating user stories, acceptance criteria, and epics, allowing teams to focus on high-value activities and reduce time spent on administrative tasks.",
            aboutUsWhyWeDoIt: "Why We Do It",
            aboutUsWhyWeDoItText: "The importance of clear communication and shared understanding in achieving successful project outcomes cannot be overstated. Miscommunication and ambiguity can lead to delays, rework, and ultimately, dissatisfied customers. By leveraging AI, our tool helps ensure that user stories are precise, comprehensive, and aligned with the team’s objectives, enhancing the overall efficiency and productivity of agile teams.",
            aboutUsOurVision: "Our Vision",
            aboutUsOurVisionText: "We envision a world where agile teams are not bogged down by the repetitive and time-consuming aspects of project management. Instead, they can dedicate their energy to innovative problem-solving and delivering exceptional products. AI PO Helper is committed to supporting this vision by providing a robust and intuitive platform that integrates seamlessly into your workflow, making agile practices more accessible and effective.",
            aboutUsOurApproach: "Our Approach",
            aboutUsUserCentricDesign: "User-Centric Design",
            aboutUsUserCentricDesignText: "We prioritize the needs of product owners and agile teams, ensuring that our tool is intuitive and easy to use.",
            aboutUsAIPoweredEfficiency: "AI-Powered Efficiency",
            aboutUsAIPoweredEfficiencyText: "Our AI prompts are designed to automate the creation of user stories and acceptance criteria, saving you valuable time and effort.",
            aboutUsContinuousImprovement: "Continuous Improvement",
            aboutUsContinuousImprovementText: "We are constantly evolving our platform based on user feedback and technological advancements to provide the best possible experience.",
            aboutUsJoinUs: "Join Us on Our Journey",
            aboutUsJoinUsText: "At AI PO Helper, we are committed to revolutionizing the way agile teams work. Join us on our journey to enhance productivity, improve communication, and deliver greater value to your customers. Together, we can make agile practices more efficient and enjoyable for everyone involved.",
            // Privacy Policy
            privacyPolicyTitle: "Privacy Policy",
            privacyPolicyIntroductionTitle: "1. Introduction",
            privacyPolicyIntroductionText: "Welcome to AI PO Helper. We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, store, and safeguard your data when you use our web application. By using our services, you agree to the terms and conditions outlined in this policy.",

            privacyPolicyInformationWeCollectTitle: "2. Information We Collect",
            privacyPolicyInformationWeCollectText: "We may collect and process the following types of personal information to improve our services and provide you with a better user experience.",
            privacyPolicyAccountInformationTitle: "Account Information",
            privacyPolicyAccountInformationText: "When you create an account, we collect your name, email address, and any other information you provide.",
            privacyPolicyUsageDataTitle: "Usage Data",
            privacyPolicyUsageDataText: "We collect information about how you interact with our web application, including page views, clicks, and other activity data.",
            privacyPolicyTechnicalDataTitle: "Technical Data",
            privacyPolicyTechnicalDataText: "We collect technical information, such as IP address, browser type, device information, and operating system.",
            privacyPolicyAnalyticsDataTitle: "Analytics Data",
            privacyPolicyAnalyticsDataText: "We may use Google Analytics to understand how users interact with our platform, including traffic sources, user behavior, and other usage data.",

            privacyPolicyHowWeUseYourInformationTitle: "3. How We Use Your Information",
            privacyPolicyHowWeUseYourInformationText: "We use your personal information for the following purposes:",
            privacyPolicyProvideServicesTitle: "To Provide Services",
            privacyPolicyProvideServicesText: "To enable you to create epics, features, and user stories as part of our AI-powered agile methodology tool.",
            privacyPolicyImprovePlatformTitle: "To Improve Our Platform",
            privacyPolicyImprovePlatformText: "To analyze usage trends and improve our services, features, and user experience.",
            privacyPolicyCommunicateWithYouTitle: "To Communicate with You",
            privacyPolicyCommunicateWithYouText: "To send you updates, respond to your inquiries, and provide support.",
            privacyPolicySecurityTitle: "For Security Purposes",
            privacyPolicySecurityText: "To protect our users and platform from unauthorized access, fraud, and abuse.",

            privacyPolicyDataProtectionTitle: "4. Data Protection",
            privacyPolicyDataProtectionText: "We implement measures to protect your data from unauthorized access and misuse.",
            privacyPolicyDataEncryptionTitle: "Data Encryption",
            privacyPolicyDataEncryptionText: "All sensitive information, including passwords, is encrypted using industry-standard encryption protocols.",
            privacyPolicyDataStorageTitle: "Data Storage",
            privacyPolicyDataStorageText: "Your information is stored securely in a database with strict access controls.",
            privacyPolicyDataRetentionTitle: "Data Retention",
            privacyPolicyDataRetentionText: "We retain your data only for as long as necessary to fulfill the purposes outlined in this Privacy Policy.",

            privacyPolicySharingInformationTitle: "5. Sharing of Information",
            privacyPolicySharingInformationText: "We do not sell, trade, or share your personal information with third parties, except as necessary to provide our services, comply with legal obligations, or protect our rights.",

            privacyPolicyCookiesTrackingTechnologiesTitle: "6. Cookies and Tracking Technologies",
            privacyPolicyCookiesTrackingTechnologiesText: "We use cookies and similar tracking technologies to enhance your experience on our platform. Cookies help us remember your preferences and understand how you interact with our application.",

            privacyPolicyYourRightsTitle: "7. Your Rights",
            privacyPolicyRightAccessTitle: "Access and Correction",
            privacyPolicyRightAccessText: "You have the right to request access to and correction of your personal data.",
            privacyPolicyRightDeletionTitle: "Data Deletion",
            privacyPolicyRightDeletionText: "You may request the deletion of your data at any time.",
            privacyPolicyRightPortabilityTitle: "Data Portability",
            privacyPolicyRightPortabilityText: "You may request that we transfer your personal data to another service provider.",

            privacyPolicyChangesTitle: "8. Changes to this Privacy Policy",
            privacyPolicyChangesText: "We may update this Privacy Policy from time to time. We encourage you to review this policy periodically.",

            privacyPolicyContactUsTitle: "9. Contact Us",
            privacyPolicyContactUsText: "If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us at",

            privacyPolicyEffectiveDateTitle: "Effective Date",
            privacyPolicyEffectiveDate: "06.01.2024",

            // Terms and Conditions
            termsTitle: "Terms and Conditions",

            termsAcceptanceTitle: "1. Acceptance of Terms",
            termsAcceptanceText: "By accessing or using our web application, you agree to be bound by these Terms and Conditions. If you do not agree to these terms, you may not use our services.",

            termsServicesTitle: "2. Description of Services",
            termsServicesText: "AI PO Helper provides tools for agile project management, enabling the creation of epics, features, user stories, backlog management, progress tracking, and team performance analytics. The platform continuously evolves to offer new functionalities to support agile methodologies.",

            termsAccountsTitle: "3. User Accounts and Responsibilities",
            termsAccountCreationTitle: "Account Creation",
            termsAccountCreationText: "Users must create an account to access certain features. You are responsible for maintaining the confidentiality of your login credentials.",
            termsAccountSecurityTitle: "Account Security",
            termsAccountSecurityText: "You are responsible for all activities under your account. Notify us immediately if you suspect unauthorized access.",
            termsAccountSuspensionTitle: "Account Suspension",
            termsAccountSuspensionText: "We reserve the right to suspend or terminate your account if you violate these Terms and Conditions.",

            termsPlatformUseTitle: "4. Use of the Platform",
            termsPermittedUseTitle: "Permitted Use",
            termsPermittedUseText: "The platform may be used for lawful purposes related to agile project management and product development.",
            termsProhibitedUseTitle: "Prohibited Use",
            termsProhibitedUseText: "Users are prohibited from attempting to access our system unlawfully, disrupting services, or using AI-generated content as proprietary work.",

            termsIntellectualPropertyTitle: "5. Intellectual Property",
            termsOwnershipTitle: "Ownership",
            termsOwnershipText: "All content, branding, design, and software are the property of AI PO Helper.",
            termsUserGeneratedContentTitle: "User-Generated Content",
            termsUserGeneratedContentText: "Content generated using the platform is the user's responsibility and may not be free of third-party rights.",

            termsPaymentsTitle: "6. Payments and Subscription",
            termsPricingTitle: "Pricing",
            termsPricingText: "Some features may have fees. Prices are displayed on the platform and are subject to change.",
            termsPaymentsProcessingTitle: "Payments",
            termsPaymentsProcessingText: "Payments are processed securely. Failed payments may restrict access to features.",
            termsRefundsTitle: "Refunds",
            termsRefundsText: "Refunds are reviewed on a case-by-case basis and may be denied if the service has been used.",

            termsLimitationLiabilityTitle: "7. Limitation of Liability",
            termsLimitationLiabilityText: "AI PO Helper is provided 'as is' without warranties. We disclaim liability for errors, interruptions, or loss of content.",

            termsPrivacyTitle: "8. Privacy and Data Protection",
            termsPrivacyText: "We prioritize your privacy. For details on how we manage personal data, please see our Privacy Policy.",

            termsModificationsTitle: "9. Modifications to the Terms",
            termsModificationsText: "These terms may be updated periodically. Continued use of the platform constitutes acceptance of the updated terms.",

            termsTerminationTitle: "10. Termination of Services",
            termsTerminationText: "We reserve the right to terminate or suspend access if these terms are violated or if we decide to discontinue the platform.",

            termsGoverningLawTitle: "11. Governing Law and Dispute Resolution",
            termsGoverningLawText: "These terms are governed by the laws of Chile. Disputes will be resolved via arbitration according to Chilean laws.",

            termsContactUsTitle: "12. Contact Us",
            termsContactUsText: "For questions or concerns regarding these Terms and Conditions, contact us at",

            termsEffectiveDateTitle: "Effective Date",
            termsEffectiveDate: "06.01.2024",

            // Use Cases

            useCasesTitle: "Use Cases for AI PO Helper",

            useCaseCostReductionTitle: "1. Cost Reduction and Resource Optimization",
            useCaseCostReductionText: "One of the key benefits of AI PO Helper is its ability to optimize resources and reduce operational costs. By streamlining the process of creating epics, features, and user stories, a single Product Owner can efficiently manage multiple backlogs simultaneously. This reduces the dependency on multiple Product Owners, thereby minimizing staffing costs and increasing operational efficiency.",

            useCaseEfficiencyTitle: "2. Increased Efficiency in User Story Creation and Refinement",
            useCaseEfficiencyText: "Manual creation and refinement of user stories can be time-consuming for agile teams. AI PO Helper significantly reduces this time by generating high-quality, ready-to-use user stories and acceptance criteria. Agile teams can shift their focus from administrative tasks to value-driven activities, leading to faster sprint planning and improved development velocity.",

            useCaseOnboardingTitle: "3. Onboarding and Training for New Product Owners",
            useCaseOnboardingText: "For Product Owners who are new to agile methodologies, AI PO Helper serves as a learning and support tool. It provides clear templates and guidance on how to create effective user stories, epics, and features. This not only accelerates the onboarding process for new Product Owners but also ensures that the agile process is followed correctly from the beginning.",

            useCaseCreativityTitle: "4. Boosting Creativity and Innovation in Product Development",
            useCaseCreativityText: "AI PO Helper goes beyond operational efficiency by fostering creativity and innovation in product development. By offering AI-generated suggestions for user stories and features, it empowers teams to explore new possibilities. This leads to the development of more diverse and creative product ideas that might not have been considered otherwise.",

            useCaseStandardizationTitle: "5. Standardization of User Story Quality",
            useCaseStandardizationText: "Inconsistent user story quality can lead to misunderstandings between stakeholders, development teams, and Product Owners. AI PO Helper ensures consistency by providing standardized formats and templates for user stories, epics, and features. This promotes clarity, reduces ambiguity, and enhances cross-team alignment.",

            useCaseBacklogTitle: "6. Faster Backlog Grooming and Prioritization",
            useCaseBacklogText: "Grooming the product backlog is a critical but time-consuming activity. AI PO Helper accelerates backlog grooming by generating refined and prioritized user stories based on inputs from the Product Owner. This allows agile teams to focus on high-priority tasks, reducing the time spent on backlog refinement sessions.",

            useCaseRemoteSupportTitle: "7. Support for Remote and Distributed Teams",
            useCaseRemoteSupportText: "With remote work becoming more common, agile teams often face challenges in collaboration and alignment. AI PO Helper supports distributed teams by providing a centralized platform where Product Owners, Scrum Masters, and team members can access, create, and refine user stories in real time. This ensures that everyone is on the same page, regardless of location.",

            // ProductTree
            productTreeCloseButton: "Close",
            productTreeOpenButton: "Open",
            productTreeEpicsLabel: "Epics",
            productTreeStoriesLabel: "Stories",
            productTreeCriteriaLabel: "Criteria"
        }
    },
    es: {
        translation: {
            header: "AI PO Helper",
            contextLabel: "Haz una descripción  del producto: qué es, su propósito, a quién está dirigido, funcionalidades, tecnologías y cualquier información relevante. Mientras más contexto, mejor.",
            functionalityLabel: "Nombra la funcionalidad específica a desarrollar",
            generateButton: "Generar historia de usuario",
            generatingButton: "Generando...",
            decomposeButton: "Descomponer en más historias",
            copyButton: "Copiar",
            userStory: "Historia de usuario:",
            additionalInfoPlaceholder: "Introduce información adicional (opcional)",
            errorGeneratingStory: "Error al generar la historia de usuario:",
            generateUserStories: "Descomponer historia de usuario",
            generatingUserStories: "Descomponiendo historias de usuario...",
            decomposedStories: "Historias Descompuestas:",
            navBarTitle: "",
            additionalInfoHeader: "Información adicional de la historia",
            introText: "AI PO Helper es una plataforma que, por medio de la Inteligencia Artificial, te ayuda a optimizar tus tareas como Product Owner, ahorrándote tiempo y apoyándote en desarrollar productos de alta calidad.",
            describeProduct: "Describe tu producto",

            // promptTemplate1: "Dado el contexto de desarrollo y construcción del producto, incluyendo detalles del usuario y propósito: '{{context}}'.\n\nGenera una historia de usuario basada en la funcionalidad específica: '{{functionality}}', en el formato: 'Como <rol>, quiero <acción a realizar> para <objetivo>'. La historia debe comenzar con 'Título: ' seguido de un breve título que resuma la acción principal en pocas palabras, y luego el texto de la historia. Mantén el texto de la historia conciso, corto y directo (máximo 4 líneas de largo), y recuerda respetar el formato, en especial siempre y sin expeción debe seguir el formato mencionado: 'Como <rol>, quiero <acción a realizar> para <objetivo>'",
            promptTemplate: "GENERAR HISTORIA USANDO|| CONTEXTO:{{ context }}|| ÉPICA: { { epic } }||** Formato EXACTO:** 1.'Título: [3-5 palabras]' 2.'Como [rol], quiero [acción] para [objetivo]' ||** Reglas:**|| - ROL debe relacionarse con { { context } }| - ACCIÓN usar verbos de { { epic } }| - PROHIBIDO: 'poder', 'deseo', 'pueda', 'necesito' ||** Ejemplo Contextual:**|| Si contexto = 'App médica' y épica = 'Gestión de pacientes'→ 'Título: Historial completo\\nComo médico tratante, quiero acceder al historial completo del paciente para diagnosticar con precisión'",


            promptDecomposeTemplate: "Basado en la historia original: '{{originalStory}}', y considerando el contexto adicional proporcionado: '{{additionalInfo}}', por favor, genera historias de usuario más específicas que estén directamente relacionadas con la funcionalidad y el contexto originales. Cada historia debe comenzar con 'Título: ' seguido de un breve título que resuma la acción principal en pocas palabras, y luego el texto de la historia. Cada historia debe estar separada por saltos de línea, sin números ni caracteres especiales antes o después del texto de la historia.",
            homeTitle: "Optimiza tu productividad en el mundo de la agilidad con IA",
            titleGenerateStory: "User Stories",
            homeTitleGenerateStory: "Crea historias de usario fácilmente",
            homeDescriptionGenerateStory: "¡Mejora el entendimiento de tu equipo y consigue mejores resultados!",
            descriptionGenerateStory: "Concentrate en lo realmente importante y genera historias de usuario completas y de alta calidad",
            cardAcceptanceCriteriaTitle: "Criterios de aceptación",
            "promptAcceptanceCriteria": "Genera un listado enumerado de criterios de aceptación detallados, cada uno separado por un salto de línea. El enfoque de los criterios debe estar en lo que dice la siguiente historia de usuario proporcionada, que es Historia: {{storyDescription}} y teniendo en cuenta el contexto del producto, que es Contexto: {{context}}, asegúrate de cubrir todos los aspectos técnicos y funcionales. Responde solo con el listado.",
            generateAcceptanceCriButton: "Generar criterios de aceptación",
            copyToClipboardButton: "Copiar al portapapeles",
            landingStartButton: "¡Comienza gratis!",
            advantagesLanding: "Destacados",
            titleBlock1Landing: "Automatiza tus historias",
            descBlock1Landing: "Genera historias de usuario automáticamente.|Captura requisitos con facilidad y precisión.|Ideal para mantener la coherencia en la documentación de proyectos.",
            titleBlock2Landing: "Ahorra tiempo",
            "descBlock2Landing": "Reduce drásticamente el tiempo de planificación.|Libera recursos para desarrollo y diseño.|Automatiza procesos repetitivos y mejora la eficiencia.",
            titleBlock3Landing: "Crea mejores productos",
            "descBlock3Landing": "Eleva la calidad del producto final.|Asegura que las expectativas de usuarios y stakeholders sean claras y alcanzables.|Minimiza los riesgos de malentendidos y asegura un entendimiento común.",
            "generateEpicsButton": "Generar",
            "generateEpicsDesc": "Divide el desarrollo de tu producto en épicas para planificar su construcción de la mejor manera.",
            "titleGenerateAcceptanceCriteria": "Criterios de aceptación",
            "descriptionAcceptanceCriteria": "Genera los criterios de aceptación para tus historias de usuario.",
            "nameUserStoryLabel": "Ingresa la historia de usuario de la que quieres los criterios de aceptación",
            "generateAcceptanceCriteriaButton": "Generar Criterios de Aceptación",
            "workspaceMainTitle": "Selecciona una opción del menú",
            "menuButtonEpics": "Épicas",
            "menuButtonUserStories": "Historias de usuario",
            "menuButtonCriteria": "Criterios de aceptación",
            // Traducciones WelcomeCard
            "welcomeCardTitle": "AI PO Helper",
            "welcomeCardDescription": "Selecciona una opción del menú para comenzar, o usa los botones de abajo para crear nuevo contenido.",
            "welcomeCardDescriptionPrivate": "¡Bienvenido! Selecciona un producto en el menú para comenzar o crea uno en la sección dedicada para ello.",
            "welcomeCardDescriptionNoProductPrivate": "¡Bienvenido! Crea un nuevo producto para comenzar.",
            "welcomeEpicButton": "Épicas",
            "welcomeStoryButton": "Historias de Usuario",
            "welcomeCriteriaButton": "Criterios de Aceptación",
            "welcomeStartButton": "Comenzar",
            // ProductForm
            createProductTitle: "Producto",
            createProductDesc: "Un producto es un ítem o sistema desarrollado para satisfacer las necesidades del usuario, entregando valor a través del desarrollo iterativo e incremental",
            productNameLabel: "Nombre del producto",
            productDescriptionLabel: "Descripción del Producto",
            saveProduct: "Guardar",
            cancelProduct: "Cancelar",
            editProductTitle: "Editar Producto",
            addProductTitle: "Agregar Producto",
            searchProductTitle: "Buscar y Editar Producto",
            filterStatusLabel: "Filtrar por estado",
            searchByNameLabel: "Buscar por Nombre",
            productSavedSuccess: "Producto guardado correctamente",
            productSaveError: "Error al guardar el producto",
            deleteProduct: "Eliminar",
            productDeletedSuccess: "Producto eliminado correctamente",
            productDeletedError: "Error al eliminar el producto",
            productAddDescPlaceHolder: "Incluir al menos qué es el producto (app, web, etc.), su finalidad, usuarios principales, funcionalidades y aspectos técnicos. A mayor detalle, mejor calidad.",
            // Menú
            menuButtonProduct: "Productos",
            menuButtonAddProduct: "Agregar Producto",
            menuButtonEditProduct: "Editar Producto",
            // Épicas
            generateEpicsTitle: "Generar Épicas",
            addEpicTitle: "Añadir Épica",
            editEpicTitle: "Editar Épica",
            all: "Todas",
            active: "Activo",
            inactive: "Inactivo",
            in_development: "En Desarrollo",
            selectProductLabel: "Seleccionar Producto",
            // "epicsPrompt": `Genera una serie de épicas a partir de la descripción del producto proporcionada: "{{context}}". Para cada épica, proporciona un título en negrita y utilizando doble asterisco y sigue con una descripción en la línea siguiente. No enumeres las épicas. Cada épica y su descripción deben estar separadas de la siguiente por dos saltos de línea. Asegúrate de que la respuesta contenga solo los títulos y descripciones de las épicas, sin texto adicional o historias de usuario.`,
            "epicsPrompt": `Genera todas las épicas necesarias para desarrolla con éxito el producto a partir de la descripción del producto proporcionada: "{{context}}". Para cada épica proporciona un título no genérico y una descripción de no más de 3 líneas. Solo imprime el titulo generado de la épica y su descripción, no uses número de listado ni dos puntos (:) después del título de la épica. Respeta estrictamente el formato, en donde entre cada épica debe haber un doble salto de línea. Formato: Titulo \n Descripcion \n\n Titulo \n Descripcion \n\n ...`,
            epicTitleLabel: "Título de la Épica",
            epicDescriptionLabel: "Descripción de la Épica",
            saveEpic: "Guardar",
            cancelEpic: "Cancelar",
            epicSavedSuccess: "¡Épica guardada con éxito!",
            epicSaveError: "Error al guardar la épica.",
            epicUpdatedSuccess: "¡Épica actualizada con éxito!",
            epicUpdateError: "Error al actualizar la épica.",
            epicDeletedSuccess: "¡Épica eliminada con éxito!",
            epicDeleteError: "Error al eliminar la épica.",
            selectEpicLabel: "Seleccionar Épica",
            deleteEpic: "Eliminar",
            // Historias de usuario
            "generateStoryTitle": "Generar Historia",
            "addStoryTitle": "Agregar Historia",
            "editStoryTitle": "Editar Historia",
            "selectStoryLabel": "Seleccionar Historia",
            "storyTitleLabel": "Título de la Historia",
            "storyDescriptionLabel": "Descripción de la Historia",
            "generateStoriesButton": "Generar Historia de Usuario",
            "saveStory": "Guardar Historia",
            "cancelStory": "Cancelar",
            "deleteStory": "Eliminar Historia",
            "storySavedSuccess": "Historia guardada con éxito",
            "storySaveError": "Error al guardar la historia",
            "storyUpdatedSuccess": "Historia actualizada con éxito",
            "storyUpdateError": "Error al actualizar la historia",
            "storyDeletedSuccess": "Historia eliminada con éxito",
            "storyDeleteError": "Error al eliminar la historia",
            "saveStoryButton": "Guardar Historia",
            "storiesPrompt": "Genera una serie de historias de usuario basadas en el contexto de la épica proporcionada: '{{context}}'. Para cada historia, proporciona un título y una descripción. No enumeres las historias. Cada historia y su descripción deben estar separadas de la siguiente por dos saltos de línea.",
            // Criterios de aceptación
            "generateCriteriaTitle": "Generar Criterios",
            "addCriteriaTitle": "Agregar Criterio",
            "editCriteriaTitle": "Editar Criterios",
            "selectCriteriaLabel": "Seleccionar Criterios de Aceptación",
            "criteriaDescriptionLabel": "Descripción de los Criterios de Aceptación",
            "generateCriteriaButton": "Generar",
            "saveCriteria": "Guardar",
            "cancelCriteria": "Cancelar",
            "saveCriteriaButton": "Guardar",
            "criteriaSavedSuccess": "Criterios de Aceptación guardados con éxito.",
            "criteriaSaveError": "Error al guardar los Criterios de Aceptación.",
            "criteriaUpdatedSuccess": "Criterios de Aceptación actualizados con éxito.",
            "criteriaUpdateError": "Error al actualizar los Criterios de Aceptación.",
            "criteriaDeletedSuccess": "Criterios de Aceptación eliminados con éxito.",
            "criteriaDeleteError": "Error al eliminar los Criterios de Aceptación.",
            "errorGeneratingCriteria": "Error al generar los Criterios de Aceptación:",
            deleteCriteria: "Eliminar",
            // Verificar selección de producto
            "selectProductSnackbar": "Por favor, selecciona un producto antes de proceder.",
            // Sesión
            "signOut": "Sign Out",
            "signUpTitle": "Inscríbete",
            // Generar todas las historias
            // "promptGenerateEpicStories": "Dado el contexto de desarrollo y construcción del producto, incluyendo detalles del usuario y propósito: '{{context}}'. Considerando la descripción de la épica: '{{epicDescription}}'.\n\nGenera una lista de historias de usuario que abarquen la épica. Cada historia debe seguir el formato: 'Como <rol>, quiero <acción a tomar> para <objetivo>. Cada historia debe comenzar con 'Título: ' seguido de un breve título que resuma la acción principal en pocas palabras, y luego el texto de la historia.'",
            "promptGenerateEpicStories": "Dado el contexto de desarrollo y construcción del producto, incluyendo detalles del usuario y propósito: '{{context}}'. Considerando la descripción de la épica: '{{epicDescription}}'.\n\nGenera una lista de historias de usuario que abarquen la épica. Cada historia debe seguir el formato: 'Como <rol>, quiero <acción a tomar> para <objetivo>. Cada historia debe comenzar con 'Título: ' seguido de un breve título que resuma la acción principal en pocas palabras, y luego el texto de la historia. [formato:'Título: <titulo_generado> Como <rol>, quiero <acción a tomar> para <objetivo>']",
            "generateEpicStoriesTitle": "Generar todas",
            "promptDecomposeEpicStories": "Basado en la historia original: '{{originalStory}}', y considerando el contexto adicional proporcionado: '{{additionalInfo}}', por favor genera historias de usuario más específicas que estén directamente relacionadas con la funcionalidad y el contexto original. Cada historia debe comenzar con 'Título: ' seguido de un breve título que resuma la acción principal en pocas palabras, y luego el texto de la historia. Cada historia debe estar separada por saltos de línea, sin números o caracteres especiales antes o después del texto de la historia.",
            // Footer
            linksTitle: "Enlaces",
            aboutUsLinkTitle: "Acerca de Nosotros",
            blogLinkTitle: "Blog",
            tutorialsLinkTitle: "Tutoriales",
            faqsLinkTitle: "FAQs",
            contactLinkTitle: "Contacto",
            resourcesTitle: "Recursos",
            useCasesLinkTitle: "Casos de Uso",
            testimonialsLinkTitle: "Testimonios",
            legalTitle: "Legal",
            termsLinkTitle: "Términos y Condiciones",
            privacyLinkTitle: "Política de Privacidad",
            // AboutUs
            aboutUsTitle: "Acerca de Nosotros",
            aboutUsWhoWeAre: "Quiénes Somos",
            aboutUsWhoWeAreText: "En AI PO Helper, somos apasionados por la construcción de productos digitales y por la productividad a través de la metodología ágil. Nuestra misión es crear una herramienta que empodere a cada equipo para que se concentre en lo que realmente importa: entregar valor a los clientes. Nuestro viaje comenzó con una profunda comprensión de los desafíos que enfrentan los product owners y los equipos ágiles, donde la escasez de tiempo durante la planificación, la escritura de historias de usuario y la comunicación efectiva con los equipos de desarrollo a menudo conduce a ineficiencias y malentendidos.",
            aboutUsOurMission: "Nuestra Misión",
            aboutUsOurMissionText: "Hemos experimentado de primera mano la presión de los horarios ajustados y la lucha por mantener la claridad y la comprensión en nuestros objetivos. Por eso creemos en el poder transformador de la Inteligencia Artificial (IA) para ayudar en estas tareas cruciales. AI PO Helper está diseñado para optimizar el proceso de creación de historias de usuario, criterios de aceptación y épicas, permitiendo a los equipos centrarse en actividades de alto valor y reducir el tiempo dedicado a tareas administrativas.",
            aboutUsWhyWeDoIt: "Por Qué Lo Hacemos",
            aboutUsWhyWeDoItText: "La importancia de una comunicación clara y un entendimiento compartido para lograr resultados exitosos en los proyectos no puede ser subestimada. La mala comunicación y la ambigüedad pueden llevar a retrasos, rehacer trabajo y, en última instancia, a clientes insatisfechos. Al aprovechar la IA, nuestra herramienta ayuda a garantizar que las historias de usuario sean precisas, completas y alineadas con los objetivos del equipo, mejorando la eficiencia y productividad general de los equipos ágiles.",
            aboutUsOurVision: "Nuestra Visión",
            aboutUsOurVisionText: "Imaginamos un mundo donde los equipos ágiles no estén agobiados por los aspectos repetitivos y que consumen tiempo de la gestión de proyectos. En su lugar, pueden dedicar su energía a resolver problemas de manera innovadora y a entregar productos excepcionales. AI PO Helper está comprometido con esta visión al proporcionar una plataforma robusta e intuitiva que se integra perfectamente en su flujo de trabajo, haciendo que las prácticas ágiles sean más accesibles y efectivas.",
            aboutUsOurApproach: "Nuestro Enfoque",
            aboutUsUserCentricDesign: "Diseño Centrado en el Usuario",
            aboutUsUserCentricDesignText: "Priorizamos las necesidades de los product owners y los equipos ágiles, asegurando que nuestra herramienta sea intuitiva y fácil de usar.",
            aboutUsAIPoweredEfficiency: "Eficiencia Potenciada por IA",
            aboutUsAIPoweredEfficiencyText: "Nuestros prompts de IA están diseñados para automatizar la creación de historias de usuario y criterios de aceptación, ahorrándote tiempo y esfuerzo valiosos.",
            aboutUsContinuousImprovement: "Mejora Continua",
            aboutUsContinuousImprovementText: "Estamos constantemente evolucionando nuestra plataforma en base a la retroalimentación de los usuarios y los avances tecnológicos para ofrecer la mejor experiencia posible.",
            aboutUsJoinUs: "Únete a Nuestro Viaje",
            aboutUsJoinUsText: "En AI PO Helper, estamos comprometidos a revolucionar la forma en que trabajan los equipos ágiles. Únete a nosotros en nuestro viaje para mejorar la productividad, la comunicación y entregar mayor valor a tus clientes. Juntos, podemos hacer que las prácticas ágiles sean más eficientes y agradables para todos los involucrados.",
            // Política de Privacidad
            privacyPolicyTitle: "Política de Privacidad",

            privacyPolicyIntroductionTitle: "1. Introducción",
            privacyPolicyIntroductionText: "Bienvenido a AI PO Helper. Nos comprometemos a proteger su privacidad y garantizar la seguridad de su información personal. Esta Política de Privacidad explica cómo recopilamos, usamos, almacenamos y protegemos sus datos cuando utiliza nuestra aplicación web. Al utilizar nuestros servicios, acepta los términos y condiciones descritos en esta política.",

            privacyPolicyInformationWeCollectTitle: "2. Información que Recopilamos",
            privacyPolicyInformationWeCollectText: "Podemos recopilar y procesar los siguientes tipos de información personal para mejorar nuestros servicios y brindarle una mejor experiencia de usuario.",
            privacyPolicyAccountInformationTitle: "Información de la Cuenta",
            privacyPolicyAccountInformationText: "Cuando crea una cuenta, recopilamos su nombre, dirección de correo electrónico y cualquier otra información que proporcione.",
            privacyPolicyUsageDataTitle: "Datos de Uso",
            privacyPolicyUsageDataText: "Recopilamos información sobre cómo interactúa con nuestra aplicación web, incluidas las páginas vistas, los clics y otros datos de actividad.",
            privacyPolicyTechnicalDataTitle: "Datos Técnicos",
            privacyPolicyTechnicalDataText: "Recopilamos información técnica, como la dirección IP, el tipo de navegador, la información del dispositivo y el sistema operativo.",
            privacyPolicyAnalyticsDataTitle: "Datos de Análisis",
            privacyPolicyAnalyticsDataText: "Podemos usar Google Analytics para entender cómo los usuarios interactúan con nuestra plataforma, incluidas las fuentes de tráfico, el comportamiento de los usuarios y otros datos de uso.",

            privacyPolicyHowWeUseYourInformationTitle: "3. Cómo Usamos Su Información",
            privacyPolicyHowWeUseYourInformationText: "Utilizamos su información personal para los siguientes propósitos:",
            privacyPolicyProvideServicesTitle: "Para Proporcionar Servicios",
            privacyPolicyProvideServicesText: "Para permitirle crear épicas, características e historias de usuario como parte de nuestra herramienta de metodología ágil con IA.",
            privacyPolicyImprovePlatformTitle: "Para Mejorar Nuestra Plataforma",
            privacyPolicyImprovePlatformText: "Para analizar las tendencias de uso y mejorar nuestros servicios, funciones y experiencia de usuario.",
            privacyPolicyCommunicateWithYouTitle: "Para Comunicarnos con Usted",
            privacyPolicyCommunicateWithYouText: "Para enviarle actualizaciones, responder a sus consultas y brindarle soporte.",
            privacyPolicySecurityTitle: "Para Fines de Seguridad",
            privacyPolicySecurityText: "Para proteger a nuestros usuarios y la plataforma contra el acceso no autorizado, el fraude y el abuso.",

            privacyPolicyDataProtectionTitle: "4. Protección de Datos",
            privacyPolicyDataEncryptionTitle: "Cifrado de Datos",
            privacyPolicyDataEncryptionText: "Toda la información confidencial, incluidas las contraseñas, se cifra utilizando protocolos de cifrado estándar de la industria.",
            privacyPolicyDataStorageTitle: "Almacenamiento de Datos",
            privacyPolicyDataStorageText: "Su información se almacena de forma segura en una base de datos con estrictos controles de acceso.",
            privacyPolicyDataRetentionTitle: "Retención de Datos",
            privacyPolicyDataRetentionText: "Retenemos sus datos solo durante el tiempo necesario para cumplir con los propósitos descritos en esta Política de Privacidad.",

            privacyPolicySharingInformationTitle: "5. Compartición de Información",
            privacyPolicySharingInformationText: "No vendemos, intercambiamos ni compartimos su información personal con terceros, excepto según sea necesario para proporcionar nuestros servicios, cumplir con obligaciones legales o proteger nuestros derechos.",

            privacyPolicyCookiesTrackingTechnologiesTitle: "6. Cookies y Tecnologías de Seguimiento",
            privacyPolicyCookiesTrackingTechnologiesText: "Utilizamos cookies y tecnologías de seguimiento para mejorar su experiencia en nuestra plataforma. Las cookies nos ayudan a recordar sus preferencias y comprender cómo interactúa con nuestra aplicación.",

            privacyPolicyYourRightsTitle: "7. Sus Derechos",
            privacyPolicyRightAccessTitle: "Acceso y Corrección",
            privacyPolicyRightAccessText: "Tiene derecho a solicitar acceso y corrección de sus datos personales.",
            privacyPolicyRightDeletionTitle: "Eliminación de Datos",
            privacyPolicyRightDeletionText: "Puede solicitar la eliminación de sus datos en cualquier momento.",
            privacyPolicyRightPortabilityTitle: "Portabilidad de Datos",
            privacyPolicyRightPortabilityText: "Puede solicitar que transfiramos sus datos personales a otro proveedor de servicios.",

            privacyPolicyChangesTitle: "8. Cambios en esta Política de Privacidad",
            privacyPolicyChangesText: "Podemos actualizar esta Política de Privacidad de vez en cuando. Le recomendamos que revise esta política periódicamente.",

            privacyPolicyContactUsTitle: "9. Contáctenos",
            privacyPolicyContactUsText: "Si tiene alguna pregunta, inquietud o solicitud con respecto a esta Política de Privacidad, contáctenos en",

            privacyPolicyEffectiveDateTitle: "Fecha de Vigencia",
            privacyPolicyEffectiveDate: "01.06.2024",
            // Términos y Condiciones
            termsTitle: "Términos y Condiciones",

            termsAcceptanceTitle: "1. Aceptación de los Términos",
            termsAcceptanceText: "Al acceder o utilizar nuestra aplicación web, usted acepta quedar obligado por estos Términos y Condiciones. Si no está de acuerdo con estos términos, no podrá utilizar nuestros servicios.",

            termsServicesTitle: "2. Descripción de los Servicios",
            termsServicesText: "AI PO Helper proporciona herramientas para la gestión de proyectos ágiles, que permiten la creación de épicas, características, historias de usuario, gestión del backlog, seguimiento de progreso y análisis de rendimiento del equipo. La plataforma evoluciona continuamente para ofrecer nuevas funcionalidades que respalden las metodologías ágiles.",

            termsAccountsTitle: "3. Cuentas de Usuario y Responsabilidades",
            termsAccountCreationTitle: "Creación de Cuenta",
            termsAccountCreationText: "Los usuarios deben crear una cuenta para acceder a ciertas funciones. Usted es responsable de mantener la confidencialidad de sus credenciales de inicio de sesión.",
            termsAccountSecurityTitle: "Seguridad de la Cuenta",
            termsAccountSecurityText: "Usted es responsable de todas las actividades realizadas bajo su cuenta. Notifíquenos de inmediato si sospecha de un acceso no autorizado.",
            termsAccountSuspensionTitle: "Suspensión de la Cuenta",
            termsAccountSuspensionText: "Nos reservamos el derecho de suspender o cancelar su cuenta si infringe estos Términos y Condiciones.",

            termsPlatformUseTitle: "4. Uso de la Plataforma",
            termsPermittedUseTitle: "Uso Permitido",
            termsPermittedUseText: "La plataforma se puede utilizar únicamente con fines legales relacionados con la gestión de proyectos ágiles y el desarrollo de productos.",
            termsProhibitedUseTitle: "Uso Prohibido",
            termsProhibitedUseText: "Se prohíbe a los usuarios intentar acceder a nuestro sistema de forma no autorizada, interrumpir los servicios o utilizar el contenido generado por IA como trabajo propio.",

            termsIntellectualPropertyTitle: "5. Propiedad Intelectual",
            termsOwnershipTitle: "Propiedad",
            termsOwnershipText: "Todo el contenido, la marca, el diseño y el software son propiedad de AI PO Helper.",
            termsUserGeneratedContentTitle: "Contenido Generado por el Usuario",
            termsUserGeneratedContentText: "El contenido generado utilizando la plataforma es responsabilidad del usuario y puede no estar libre de derechos de terceros.",

            termsPaymentsTitle: "6. Pagos y Suscripción",
            termsPricingTitle: "Precios",
            termsPricingText: "Algunas funciones pueden tener costos asociados. Los precios se muestran en la plataforma y están sujetos a cambios.",
            termsPaymentsProcessingTitle: "Pagos",
            termsPaymentsProcessingText: "Los pagos se procesan de forma segura. Los pagos fallidos pueden restringir el acceso a ciertas funciones.",
            termsRefundsTitle: "Reembolsos",
            termsRefundsText: "Los reembolsos se revisan caso por caso y pueden ser denegados si el servicio ya se ha utilizado.",

            termsLimitationLiabilityTitle: "7. Limitación de Responsabilidad",
            termsLimitationLiabilityText: "AI PO Helper se proporciona 'tal cual', sin garantías de ningún tipo. No asumimos responsabilidad por errores, interrupciones o pérdida de contenido.",

            termsPrivacyTitle: "8. Privacidad y Protección de Datos",
            termsPrivacyText: "Priorizamos su privacidad. Para obtener más detalles sobre cómo gestionamos los datos personales, consulte nuestra Política de Privacidad.",

            termsModificationsTitle: "9. Modificaciones de los Términos",
            termsModificationsText: "Estos términos pueden actualizarse periódicamente. El uso continuo de la plataforma constituye la aceptación de los términos actualizados.",

            termsTerminationTitle: "10. Terminación de los Servicios",
            termsTerminationText: "Nos reservamos el derecho de terminar o suspender el acceso si se violan estos términos o si decidimos descontinuar la plataforma.",

            termsGoverningLawTitle: "11. Ley Aplicable y Resolución de Disputas",
            termsGoverningLawText: "Estos términos se rigen por las leyes de Chile. Las disputas se resolverán mediante arbitraje de acuerdo con las leyes chilenas.",

            termsContactUsTitle: "12. Contáctenos",
            termsContactUsText: "Para cualquier pregunta o inquietud con respecto a estos Términos y Condiciones, contáctenos en",

            termsEffectiveDateTitle: "Fecha de Vigencia",
            termsEffectiveDate: "01.06.2024",

            // Casos de Uso

            useCasesTitle: "Casos de Uso de AI PO Helper",

            useCaseCostReductionTitle: "1. Reducción de Costos y Optimización de Recursos",
            useCaseCostReductionText: "Uno de los principales beneficios de AI PO Helper es su capacidad para optimizar los recursos y reducir los costos operativos. Al simplificar el proceso de creación de épicas, características e historias de usuario, un solo Product Owner puede gestionar de forma eficiente múltiples backlogs al mismo tiempo. Esto reduce la necesidad de contar con múltiples Product Owners, minimizando los costos de personal e incrementando la eficiencia operativa.",

            useCaseEfficiencyTitle: "2. Mayor Eficiencia en la Creación y Refinamiento de Historias de Usuario",
            useCaseEfficiencyText: "La creación y el refinamiento manual de historias de usuario pueden consumir mucho tiempo para los equipos ágiles. AI PO Helper reduce significativamente este tiempo al generar historias de usuario y criterios de aceptación de alta calidad, listos para usar. Los equipos ágiles pueden enfocarse en actividades de mayor valor, lo que permite una planificación de sprints más rápida y una mayor velocidad de desarrollo.",

            useCaseOnboardingTitle: "3. Incorporación y Capacitación de Nuevos Product Owners",
            useCaseOnboardingText: "Para los Product Owners que son nuevos en las metodologías ágiles, AI PO Helper actúa como una herramienta de aprendizaje y soporte. Ofrece plantillas claras y orientación sobre cómo crear historias de usuario, épicas y características efectivas. Esto no solo acelera el proceso de incorporación de nuevos Product Owners, sino que también garantiza que el proceso ágil se siga correctamente desde el principio.",

            useCaseCreativityTitle: "4. Impulso de la Creatividad e Innovación en el Desarrollo de Productos",
            useCaseCreativityText: "AI PO Helper va más allá de la eficiencia operativa al fomentar la creatividad y la innovación en el desarrollo de productos. Al ofrecer sugerencias generadas por IA para historias de usuario y características, permite a los equipos explorar nuevas posibilidades. Esto conduce al desarrollo de ideas de productos más diversas y creativas que, de otro modo, podrían no haber sido consideradas.",

            useCaseStandardizationTitle: "5. Estandarización de la Calidad de las Historias de Usuario",
            useCaseStandardizationText: "La calidad inconsistente de las historias de usuario puede generar malentendidos entre las partes interesadas, los equipos de desarrollo y los Product Owners. AI PO Helper garantiza la consistencia al proporcionar formatos y plantillas estandarizadas para historias de usuario, épicas y características. Esto promueve la claridad, reduce la ambigüedad y mejora la alineación entre los equipos.",

            useCaseBacklogTitle: "6. Aceleración de la Refinación y Priorización del Backlog",
            useCaseBacklogText: "La refinación del backlog de productos es una actividad crítica pero que consume mucho tiempo. AI PO Helper acelera la refinación del backlog al generar historias de usuario refinadas y priorizadas según las entradas del Product Owner. Esto permite a los equipos ágiles centrarse en tareas de alta prioridad, reduciendo el tiempo dedicado a las sesiones de refinamiento del backlog.",

            useCaseRemoteSupportTitle: "7. Soporte para Equipos Remotos y Distribuidos",
            useCaseRemoteSupportText: "Con el aumento del trabajo remoto, los equipos ágiles a menudo enfrentan desafíos en la colaboración y la alineación. AI PO Helper respalda a los equipos distribuidos al proporcionar una plataforma centralizada donde Product Owners, Scrum Masters y miembros del equipo pueden acceder, crear y refinar historias de usuario en tiempo real. Esto garantiza que todos estén alineados, independientemente de su ubicación.",

            // ProductTree
            productTreeCloseButton: "Cerrar",
            productTreeOpenButton: "Abrir",
            productTreeEpicsLabel: "Épicas",
            productTreeStoriesLabel: "Historias",
            productTreeCriteriaLabel: "Criterios"
        }
    }
};

i18n
    .use(initReactI18next) // Pasa la instancia de i18next a react-i18next.
    .use(LanguageDetector) // Detecta el idioma del navegador automáticamente.
    .init({
        resources,
        fallbackLng: 'en', // Lenguaje de respaldo si no se encuentra el lenguaje actual.
        detection: {
            order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
            caches: ['localStorage', 'cookie']
        },
        interpolation: {
            escapeValue: false, // No escapar valores.
        },
    });

export default i18n;
