import React from 'react';
import { Container, Typography, Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import '../css/style.css';

function PrivacyPolicy() {
    const { t } = useTranslation();

    return (
        <Container maxWidth="lg">
            <Box sx={{ my: 4 }}>
                <Typography variant="h4" gutterBottom style={{ marginBottom: '40px' }}>
                    {t('privacyPolicyTitle')}
                </Typography>
                <img src="img/privacy.jpg" alt={t('introImageAlt')} className="privacyTerms-image" />
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('privacyPolicyIntroductionTitle')}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {t('privacyPolicyIntroductionText')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('privacyPolicyInformationWeCollectTitle')}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {t('privacyPolicyInformationWeCollectText')}
                        </Typography>
                        <ul>
                            <li><strong>{t('privacyPolicyAccountInformationTitle')}</strong>: {t('privacyPolicyAccountInformationText')}</li>
                            <li><strong>{t('privacyPolicyUsageDataTitle')}</strong>: {t('privacyPolicyUsageDataText')}</li>
                            <li><strong>{t('privacyPolicyTechnicalDataTitle')}</strong>: {t('privacyPolicyTechnicalDataText')}</li>
                            <li><strong>{t('privacyPolicyAnalyticsDataTitle')}</strong>: {t('privacyPolicyAnalyticsDataText')}</li>
                        </ul>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('privacyPolicyHowWeUseYourInformationTitle')}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {t('privacyPolicyHowWeUseYourInformationText')}
                        </Typography>
                        <ul>
                            <li><strong>{t('privacyPolicyProvideServicesTitle')}</strong>: {t('privacyPolicyProvideServicesText')}</li>
                            <li><strong>{t('privacyPolicyImprovePlatformTitle')}</strong>: {t('privacyPolicyImprovePlatformText')}</li>
                            <li><strong>{t('privacyPolicyCommunicateWithYouTitle')}</strong>: {t('privacyPolicyCommunicateWithYouText')}</li>
                            <li><strong>{t('privacyPolicySecurityTitle')}</strong>: {t('privacyPolicySecurityText')}</li>
                        </ul>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('privacyPolicyDataProtectionTitle')}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {t('privacyPolicyDataProtectionText')}
                        </Typography>
                        <ul>
                            <li><strong>{t('privacyPolicyDataEncryptionTitle')}</strong>: {t('privacyPolicyDataEncryptionText')}</li>
                            <li><strong>{t('privacyPolicyDataStorageTitle')}</strong>: {t('privacyPolicyDataStorageText')}</li>
                            <li><strong>{t('privacyPolicyDataRetentionTitle')}</strong>: {t('privacyPolicyDataRetentionText')}</li>
                        </ul>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('privacyPolicySharingInformationTitle')}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {t('privacyPolicySharingInformationText')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('privacyPolicyCookiesTrackingTechnologiesTitle')}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {t('privacyPolicyCookiesTrackingTechnologiesText')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('privacyPolicyYourRightsTitle')}
                        </Typography>
                        <ul>
                            <li><strong>{t('privacyPolicyRightAccessTitle')}</strong>: {t('privacyPolicyRightAccessText')}</li>
                            <li><strong>{t('privacyPolicyRightDeletionTitle')}</strong>: {t('privacyPolicyRightDeletionText')}</li>
                            <li><strong>{t('privacyPolicyRightPortabilityTitle')}</strong>: {t('privacyPolicyRightPortabilityText')}</li>
                        </ul>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('privacyPolicyChangesTitle')}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {t('privacyPolicyChangesText')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('privacyPolicyContactUsTitle')}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {t('privacyPolicyContactUsText')} <a href="mailto:hello@pohelper.com">hello@pohelper.com</a>
                        </Typography>
                    </Grid>
                </Grid>

                <Typography variant="body2" align="center">
                    <strong>{t('privacyPolicyEffectiveDateTitle')}</strong>: {t('privacyPolicyEffectiveDate')}
                </Typography>
            </Box>
        </Container>
    );
}

export default PrivacyPolicy;
