import React from 'react';
import { Box, Container, Typography, Link, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

function Footer() {
    const { t } = useTranslation();
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Box component="footer" sx={{ bgcolor: '#f5f5f5', py: 4, mt: 6, borderTop: '1px solid #e0e0e0' }}>
            <Container maxWidth="lg">
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6" color="text.primary" gutterBottom align="left" sx={{ fontSize: '1rem' }}>
                            AI PO Helper
                        </Typography>
                        <Typography variant="body2" color="text.secondary" align="left" sx={{ fontSize: '0.875rem' }}>
                            <Link color="inherit" href="/about-us">
                                {t('aboutUsLinkTitle')}
                            </Link>
                        </Typography>
                        <Typography variant="body2" color="text.secondary" align="left" sx={{ fontSize: '0.875rem' }}>
                            <Link color="inherit" href="/contact">
                                {t('contactLinkTitle')}
                            </Link>
                        </Typography>
                        <Typography variant="body2" color="text.secondary" align="left" sx={{ fontSize: '0.875rem' }}>
                            © {new Date().getFullYear()} AI PO Helper
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6" color="text.primary" gutterBottom align="left" sx={{ fontSize: '1rem' }}>
                            {t('linksTitle')}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" align="left" sx={{ fontSize: '0.875rem' }}>
                            <Link color="inherit" href="/blog">
                                {t('blogLinkTitle')}
                            </Link>
                        </Typography>
                        {/*                         <Typography variant="body2" color="text.secondary" align="left" sx={{ fontSize: '0.875rem' }}>
                            <Link color="inherit" href="/tutorials">
                                {t('tutorialsLinkTitle')}
                            </Link>
                        </Typography> */}
                        <Typography variant="body2" color="text.secondary" align="left" sx={{ fontSize: '0.875rem' }}>
                            <Link color="inherit" href="/faqs">
                                {t('faqsLinkTitle')}
                            </Link>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6" color="text.primary" gutterBottom align="left" sx={{ fontSize: '1rem' }}>
                            {t('resourcesTitle')}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" align="left" sx={{ fontSize: '0.875rem' }}>
                            <Link color="inherit" href="/use-cases">
                                {t('useCasesLinkTitle')}
                            </Link>
                        </Typography>
                        {/*                         <Typography variant="body2" color="text.secondary" align="left" sx={{ fontSize: '0.875rem' }}>
                            <Link color="inherit" href="/testimonials">
                                {t('testimonialsLinkTitle')}
                            </Link>
                        </Typography> */}
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6" color="text.primary" gutterBottom align="left" sx={{ fontSize: '1rem' }}>
                            {t('legalTitle')}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" align="left" sx={{ fontSize: '0.875rem' }}>
                            <Link color="inherit" href="/terms-and-conditions">
                                {t('termsLinkTitle')}
                            </Link>
                        </Typography>
                        <Typography variant="body2" color="text.secondary" align="left" sx={{ fontSize: '0.875rem' }}>
                            <Link color="inherit" href="/privacy-policy">
                                {t('privacyLinkTitle')}
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default Footer;
