import React from 'react';
import { Container, Typography, Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import '../css/style.css';

function TermsAndConditions() {
    const { t } = useTranslation();

    return (
        <Container maxWidth="lg">
            <Box sx={{ my: 4 }}>
                <Typography variant="h4" gutterBottom style={{ marginBottom: '40px' }}>
                    {t('termsTitle')}
                </Typography>
                <img src="img/legal.jpg" alt={t('introImageAlt')} className="termsAndConditions-image" />
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('termsAcceptanceTitle')}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {t('termsAcceptanceText')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('termsServicesTitle')}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {t('termsServicesText')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('termsAccountsTitle')}
                        </Typography>
                        <ul>
                            <li><strong>{t('termsAccountCreationTitle')}</strong>: {t('termsAccountCreationText')}</li>
                            <li><strong>{t('termsAccountSecurityTitle')}</strong>: {t('termsAccountSecurityText')}</li>
                            <li><strong>{t('termsAccountSuspensionTitle')}</strong>: {t('termsAccountSuspensionText')}</li>
                        </ul>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('termsPlatformUseTitle')}
                        </Typography>
                        <ul>
                            <li><strong>{t('termsPermittedUseTitle')}</strong>: {t('termsPermittedUseText')}</li>
                            <li><strong>{t('termsProhibitedUseTitle')}</strong>: {t('termsProhibitedUseText')}</li>
                        </ul>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('termsIntellectualPropertyTitle')}
                        </Typography>
                        <ul>
                            <li><strong>{t('termsOwnershipTitle')}</strong>: {t('termsOwnershipText')}</li>
                            <li><strong>{t('termsUserGeneratedContentTitle')}</strong>: {t('termsUserGeneratedContentText')}</li>
                        </ul>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('termsPaymentsTitle')}
                        </Typography>
                        <ul>
                            <li><strong>{t('termsPricingTitle')}</strong>: {t('termsPricingText')}</li>
                            <li><strong>{t('termsPaymentsProcessingTitle')}</strong>: {t('termsPaymentsProcessingText')}</li>
                            <li><strong>{t('termsRefundsTitle')}</strong>: {t('termsRefundsText')}</li>
                        </ul>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('termsLimitationLiabilityTitle')}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {t('termsLimitationLiabilityText')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('termsPrivacyTitle')}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {t('termsPrivacyText')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('termsModificationsTitle')}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {t('termsModificationsText')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('termsTerminationTitle')}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {t('termsTerminationText')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('termsGoverningLawTitle')}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {t('termsGoverningLawText')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {t('termsContactUsTitle')}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {t('termsContactUsText')} <a href="mailto:hello@pohelper.com">hello@pohelper.com</a>
                        </Typography>
                    </Grid>
                </Grid>

                <Typography variant="body2" align="center">
                    <strong>{t('termsEffectiveDateTitle')}</strong>: {t('termsEffectiveDate')}
                </Typography>
            </Box>
        </Container>
    );
}

export default TermsAndConditions;
